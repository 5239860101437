'use strict';
jQuery(document).ready(function ($) {
  $('#vacature_form input[name=email]').focus(function () {
    $('#vacature_form input[name=cpt]').val('958');
  });
  //VACATURE FORM
  $('#vacature_form')
    // to prevent form from submitting upon successful validation
    .on('submit', function (ev) {
      ev.preventDefault();

      console.log('Submit for form id ' + ev.target.id + ' intercepted');
    })
    .on('forminvalid.zf.abide', function (ev, frm) {
      console.log('Form id ' + ev.target.id + ' is invalid');
    })
    .on('formvalid.zf.abide', function (ev, frm) {
      //check filesize
      $('#vacature_form .file-alert').hide();
      var fileList = $('#vacature_form #fileUpload')[0].files;
      var totalSize = 0;
      for (var i = 0; i < fileList.length; i++) {
        totalSize += fileList[i].size;
      }
      $('#vacature_form .button2').attr('disabled', 'true');
      $('#vacature_form .loading').css('display', 'inline-block');

      if (totalSize <= 3145728) {
        var that = $('#vacature_form'),
          url = that.attr('action'),
          method = that.attr('method'),
          data = new FormData(this);

        $.ajax({
          url: url,
          type: method,
          data: data,
          processData: false,
          cache: false,
          contentType: false,

          success: function (response) {
            console.log(response);

            if (response == 'success') {
              $('#vacature_form').hide();
              $('.form .successMessage').show();
              $('html, body').animate(
                {
                  scrollTop:
                    $('.grid-container.home.section-one .form').offset().top -
                    100,
                },
                1000
              );
            } else if (response == 'no valid filetypefail') {
              $('#vacature_form .file-size-alert').hide();
              $('#vacature_form .file-type-alert').show();
              $('#vacature_form .button1').removeAttr('disabled');
              $('#vacature_form .loading').hide();
            }
          },
          error: function (response) {
            $('#vacature_form .button1').attr('disabled', 'false');
            $('#vacature_form .loading').hide();
          },
        });
      } else {
        $('#vacature_form .file-type-alert').hide();
        $('#vacature_form .file-size-alert').show();
        $('#vacature_form .button1').removeAttr('disabled');
        $('#vacature_form .loading').hide();
      }
    });

  //INPUT FILE SHOW FILENAMES
  var fileInput = document.getElementById('fileUpload');
  var fileListDisplay = document.getElementById('file-list-display');

  var fileList = [];
  var renderFileList, sendFile;

  fileInput.addEventListener('change', function (evnt) {
    fileList = [];
    for (var i = 0; i < fileInput.files.length; i++) {
      fileList.push(fileInput.files[i]);
    }
    renderFileList();
  });

  renderFileList = function () {
    fileListDisplay.innerHTML = '';
    fileList.forEach(function (file, index) {
      var fileDisplayEl = document.createElement('p');
      fileDisplayEl.innerHTML =
        index +
        1 +
        '. ' +
        file.name +
        ' - ' +
        (file.size / 1048576).toFixed(2) +
        'mb';
      fileListDisplay.appendChild(fileDisplayEl);
    });
  };
});
